.card {
  width: calc(50% - 0.75rem);
  display: flex;
  align-items: center;
  gap: 3rem;
  background-color: #e8f6fc;
  padding: 3rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1.5rem;
    gap: 2rem;
  }
  &Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    border-radius: 100%;
    background-color: #fff;
    height: 60px;
  }
  img {
    max-width: 35px;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    color: #103649;
  }
  &Title {
    font-size: 2rem;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 2.4rem;
    }
  }
  &Body {
    font-size: 1.5rem;
    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
}
