.projectsFilters {
  .filtersNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    @media screen and (max-width: 475px) {
      justify-content: center;
    }
  }

  .categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;

    @media screen and (max-width: 768px) {
      gap: 2rem;
    }

    span {
      color: #103649;
      font-size: 2rem;
      font-weight: 500;
      cursor: pointer;
      transition: color 0.3s ease;

      &.active,
      &:hover {
        color: #4a7fa7;
      }
    }

    .activeType {
      color: #4a7fa7;
    }
  }

  button {
    all: unset;
    border-radius: 19px;
    border: 1px solid #103649;
    padding: 1.5rem;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #103649;

    img {
      display: inline-block;
      margin-inline-end: 1.5rem;
    }
  }

  .filters {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    margin-top: 2rem;

    & > div {
      flex-basis: 100%;
    }
  }

  .projects {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    padding-block: 2rem;
    @media screen and (max-width: 650px) {
      justify-content: center;
    }
    @media screen and (max-width: 1400px) {
      gap: 2rem;
    }
    & > div {
      border-radius: 20px;
      width: calc(calc(100% / 4) - calc(4rem / 4));
      @media screen and (max-width: 1400px) {
        width: calc(calc(100% / 3) - calc(4rem / 3));
      }
      @media screen and (max-width: 950px) {
        width: calc(50% - 2rem);
      }
      @media screen and (max-width: 650px) {
        width: 60%;
      }
      @media screen and (max-width: 600px) {
        width: 65%;
      }
      @media screen and (max-width: 550px) {
        width: 70%;
      }
      @media screen and (max-width: 500px) {
        width: 80%;
      }
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }
}

.pagination {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.loading {
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.noProducts {
  font-size: 3rem;
  color: #103649;
  margin-block-start: 5rem;
  text-align: center;
}
