.video {
	width: 100%;
	height: 100%;
	position: relative;
	&Container {
		position: relative;
		height: 100%;
		width: 100%;
	}
	&Controls {
		position: absolute;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 2rem;
		font-weight: 500;
		cursor: pointer;
		&Container {
			display: flex;
			align-items: center;
			gap: 2rem;
		}
	}
	&PlayBtn {
		all: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		aspect-ratio: 1;
		width: 50px;
		background-color: #4a7fa7;
		border-radius: 100%;
		padding: 1rem;
		cursor: pointer;
		position: relative;
		left: -25px;
		&:lang(ar) {
			left: 25px;
		}
		& img {
			width: 25px;
			height: 25px;
			// transform: translateX(0.25rem);
		}
	}
}

.poster {
	&Left {
		position: absolute;
		inset: 5% 46.5% 0 0;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: cover;
		height: 99%;
		width: 45%;
		border-radius: 2rem;
		overflow: hidden;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	}
	&Right {
		position: absolute;
		inset: -5% 0 0 47.5%;
		width: 45%;
		height: 99%;
		border-radius: 2rem;
		background-position: right bottom;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	}
}

.hide {
	visibility: hidden;
}
