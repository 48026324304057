.formInfo {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #E8F6FC;
  padding: 5rem 7rem;
  @media screen and (max-width: 768px) {
    padding-inline: 5%;
  }
  &Title {
    font-size: 3rem;
    color: #103649;
    margin-block-end: 2rem;
  }
  &Body {
    font-size: 1.5rem;
    line-height: 3rem;
  }
  &Email,
  &Phone,
  &Address,
  &Social {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  &Address {
    align-items: flex-start;
  }
  &Social {
    margin-block-start: 2rem;
  }
}