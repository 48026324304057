.ourProjects {
  padding-inline: 10%;
  padding-block: calc(3% + 10rem) 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }

  h1 {
    color: #103649;
    font-size: 5rem;
    font-weight: 700;
    text-transform: capitalize;
  }
}
