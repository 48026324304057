.inputWrapper {
  input,
  textarea {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    display: block;
    width: 100%;
    transition: border 0.3s ease;
    font-size: 1.5rem;
    padding: 0 1rem 2rem 0;

    &::placeholder {
      display: block;
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    &:focus-visible {
      outline: none;
      border-color: #000;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    font-family: var(--montserrat);
  }

  &.fileInput {
    .iconWrapper {
      min-width: 55px;
      min-height: 55px;
      border-radius: 50%;
      background-color: rgba(145, 41, 49, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    label {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 3.7rem;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 2.4rem;
      cursor: pointer;
    }

    input {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.fullWidth {
  flex-basis: 100% !important;
}

.validationMessage {
  margin-top: 5px;
  color: red;
  font-size: 1.4rem !important;
}
