.about {
  padding-block: 10%;
  padding-inline: 10%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 4rem;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 35%;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Text {
    font-size: 2rem;
    color: #4a7fa7;
    font-weight: 700;
  }
  &Title {
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    &No {
      font-size: 5rem;
      color: #4a7fa7;
    }
  }
  &Body {
    font-size: 1.8rem;
    line-height: 1.5;
  }
  &Button {
    all: unset;
    margin-block-start: 2rem;
    padding: 2rem 5rem;
    border-radius: 1.5rem;
    background-color: #497fa6;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @media screen and (max-width: 768px) {
      margin-inline: auto;
    }
  }
  &Video {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
