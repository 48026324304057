.responsiveNav {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  padding-inline: 5%;
  color: #a7a9ac;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 6;
  @media screen and (max-width: 768px) {
    display: flex;
  }
  &Logo {
    display: flex;
  }
}

.black {
  transition: color 350ms ease-in-out;
  color: #000;
}

.light {
  color: #fff;
}

.hamburgerMenu {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  aspect-ratio: 1;
  padding: 1rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.line {
  position: relative;
  background-color: #497fa6;
  border-radius: 5px;
  height: 4px;
  width: 25px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: #497fa6;
    transition: all 200ms ease-in-out;
  }
  &::before {
    top: -9px;
  }
  &::after {
    top: 9px;
  }
}

.lineHide {
  background-color: #fff;
  &::before {
    transform: translate(0, 10px) rotate(45deg);
  }
  &::after {
    transform: translate(0, -10px) rotate(-45deg);
  }
}

.responsiveMenu {
  display: none;
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  inset-inline-start: 100%;
  animation: slideOut 350ms linear forwards;
  padding-block: 10rem;
  color: #000;
  z-index: 5;
  @media screen and (max-width: 768px) {
    display: block;
  }
  &List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.5rem;
    padding-inline: 5%;
    padding-block: 2rem;
    list-style: none;
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    &Item {
      font-size: 3.5rem;
      font-weight: 900;
      letter-spacing: 1px;
      cursor: pointer;
      &:last-of-type {
        margin-block-end: 5rem;
      }
    }
  }
}

.responsiveMenuAnimation {
  animation: slideIn 350ms linear forwards;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-block-start: 3rem;
  &Link {
    display: flex;
    width: 3rem;
    aspect-ratio: 1;
    justify-content: center;
    align-items: baseline;
    &Image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@keyframes slideIn {
  0% {
    inset-inline-start: 100%;
  }
  100% {
    inset-inline-start: 0;
  }
}

@keyframes slideOut {
  0% {
    inset-inline-start: 0;
  }
  100% {
    inset-inline-start: 100%;
  }
}
