.languageSwitcher {
  &Button {
    all: unset;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    padding-block: 1rem;
    cursor: pointer;
  }
  &Lang {
    padding-inline: 1rem;
    opacity: 0.5;
    &:lang(en) {
      &:nth-of-type(1) {
        border-inline-end: 0.5px solid currentColor;
      }
      &:nth-of-type(2) {
        border-inline-start: 0.5px solid currentColor;
      }
    }
    &:lang(ar) {
      &:nth-of-type(1) {
        border-inline-start: 0.5px solid currentColor;
      }
      &:nth-of-type(2) {
        border-inline-end: 0.5px solid currentColor;
      }

    }
    &.active {
      opacity: 1;
    }
  }
}