.heroSlide {
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    min-height: 75vh;
  }
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .heroTextWrapper {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 4rem;
    color: #fff;
    min-height: 100vh;
    padding-block-start: 3rem;
    padding-block-end: 10rem;
    @media screen and (max-width: 768px) {
      min-height: 85vh;
    }
  }

  h2 {
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    position: relative;
    max-width: 900px;
    padding-inline: 1rem;
    @media screen and (max-width:767.98px){
      font-size: 3rem;

    }
  }

  p {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.5;
    max-width: 700px;
    padding-inline: 1rem;
    @media screen and (max-width:767.98px){
      font-size: 1.8rem;

    }
  }

  a {
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    margin-top: 6rem;

    svg {
      color: #00a0de;
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: #10364982;
    top: 0;
  }
}
