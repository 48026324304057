.projectDetails {
  padding-inline: 10%;
  padding-block-start: calc(5% + 10rem);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 160, 222, 0.09),
    rgba(0, 160, 222, 0.09) 65%,
    #fff 65%
  );

  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }

  & > a {
    font-size: 1.6rem;
    font-weight: 500;
    color: #4a7fa7;
    display: flex;
    align-items: center;
    gap: 2rem;
    width: fit-content;

    span {
      display: inline-block;
      position: relative;
      width: 38px;
      height: 17px;
    }

    svg {
      transform: translate(10px, -10px) rotate(90deg);
      &:lang(ar) {
        transform: translate(-10px, -10px) rotate(-90deg);
      }
    }
  }

  h1 {
    // margin-block: 4rem 2rem;
    font-size: 5rem;
    font-weight: 700;
    line-height: 8rem;
    text-transform: capitalize;
    color: #103649;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 9rem;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      gap: 2rem;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    h3,
    p {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3.7rem;
      text-transform: uppercase;
      color: #103649;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      margin-top: 1rem;
      text-transform: capitalize;
    }
  }

  .carouselWrapper {
    overflow-x: hidden;
    .imgWrapper {
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
        max-height: 508px;
      }
    }
  }
}

.withoutBg {
  padding-inline: 10%;
  padding-block-end: calc(5% + 10rem) 5%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }

  article {
    margin-block-start: 5rem;
    h5 {
      color: #103649;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 4.5rem;
      margin-bottom: 2rem;
    }

    p {
      color: #103649;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 3rem;
    }
  }
}

.moreProjects {
  overflow-x: hidden;

  h5 {
    color: #103649;
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 5.5rem;
    margin-bottom: 3rem;
  }

  .slideWrapper {
    transform: scale(0.9);
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1);
    }
  }
}
