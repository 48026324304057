.projectsSection {
  padding-block-start: 5%;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  flex-wrap: wrap;
  margin: 5rem 0;
  align-items: center;

  h2 {
    font-size: 3.25rem;
    font-weight: 700;
    color: #103649;
  }

  button {
    all: unset;
    text-transform: capitalize;
    padding: 1.5rem 5rem;
    border-radius: 1.5rem;
    background-color: #497fa6;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 710px) {
    justify-content: space-between;
  }
}
