@import "../../variables.scss";

.footer {
  background: $theme-color;
  color: #fff;

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 24px;

    span {
      font-weight: 500;
    }
  }
  .contactPhone{
    display: flex;
    align-items: center;
    span{
      font-size: 1.6rem;
    }
    .phone{
      &:first-of-type{
        margin-inline-end:6px
      }
      &:last-of-type{
        margin-inline-start:6px

      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    gap: 10rem;
    flex-wrap: wrap;
    padding-block: 5rem;
    .col {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      li {
        font-size: 1.6rem;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .col:first-child {
      flex-grow: 1;
      p {
        max-width: 332px;
        margin-top: 3.2rem;
      }
    }
    @media (max-width: 768px) {
      gap: 3rem;
    }

    @media (max-width: 650px) {
      .col {
        width: 100%;

        ul {
          display: flex;
          justify-content: center;
          flex-direction: column;
          // align-items: center;
          gap: 2rem;
        }
      }
    }
  }

  .copyrights {
    padding-block: 2.7rem;
    background: rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;

    .socialMedia {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
