.formSectionWrapper {
  display: flex;
  gap: 12rem;
  padding-block: 10%;
  padding-inline-start: 10%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1200px) {
    padding-inline-start: 5%;
    gap: 6rem;
  }
  @media screen and (max-width: 768px) {
    padding-inline: 0;
    padding-block-end: 0;
    gap: 4rem;
  }
  .col {
    flex-basis: 50%;
  }

  .col:first-child {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    color: #103649;
    @media screen and (max-width: 768px) {
      padding-inline: 5%;
    }

    h2 {
      font-size: 3.25rem;
      font-weight: 700;
      max-width: 500px;
    }

    p {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      max-width: 450px;
      line-height: 1.5;
    }

    a {
      font-size: 2rem;
      font-weight: 500;
      line-height: 3.7rem;
      text-decoration-line: underline;
      color: #912931;
      align-self: flex-start;
      padding: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .col {
      flex-basis: 100%;

      &:last-child {
        padding: 0;
      }
    }
  }
}
