.dot {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
    border-radius: 50%;

    span {
        transition: all 0.3s ease;

        display: block;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background: #ccc;
    }

    &.active {
        width: 4.5rem;
        height: 4.5rem;
        border: 1px solid #4a7fa7;

        span {
            background: #103649;
        }
    }
}