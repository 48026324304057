.navbar {
  padding-inline: 10%;
  height: 10rem;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  inset-inline: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  color: #103649;
  &.light {
    color: #fff;
    & .navbarLogo {
      color: #fff;
    }
  }
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  @media screen and (max-width: 840px) {
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    visibility: hidden;
  }
  &Logo {
    display: flex;
    width: 30%;
    object-fit: contain;
    justify-content: center;
    color: #a7a9ac;
    @media screen and (max-width: 840px) {
      width: 25%;
    }
  }
  &Content {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    @media screen and (max-width: 840px) {
      gap: 1rem;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &Email {
    font-size: 1.6rem;
  }
}
