.projectCard {
  position: relative;
  color: #fff;
  max-height: 500px;
  overflow-y: hidden;
  transition: transform 0.3s ease;
  height: 430px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(16, 54, 73, 0) 0%,
    rgba(16, 54, 73, 0.88) 90.84%
  );
  @media screen and (max-width: 764px) {
    max-width: 100%;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(16, 54, 73, 0) 0%,
        rgba(16, 54, 73, 0.88) 90.84%
      );
      z-index: 0;
      transition: background 0.3s ease;
    }
  }
  .explore {
    padding: 2rem;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(74, 127, 167, 0.7);
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute;
    inset-inline-end: 2.5rem;
    inset-block-start: 2.5rem;
    z-index: 2;
    text-transform: capitalize;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.25;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .projectDetails {
    position: absolute;
    inset-block-end: 15px;
    padding: 2rem;
    z-index: 2;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 10;

    p {
      max-height: 160px;
    }

    .explore {
      opacity: 1;
    }

    a {
      &::before {
        background: rgba(16, 54, 73, 0.64);
      }
    }
  }

  @media (max-width: 768px) {
    p {
      max-height: 160px;
    }

    .explore {
      width: 100px;
      height: 100px;
      opacity: 1;
      font-size: 1.9rem;
      inset-block-start: 2.5rem;
    }

    h4 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.8rem;
    }
  }
}
