.card {
  width: calc(50% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-block-end: 2rem;
    border-block-end: 1px solid #d7e5eb;
  }
  &Number {
    font-size: 5rem;
    font-weight: 700;
    color: #4a7fa7;
  }
  &Title {
    font-size: 2.4rem;
    font-weight: 700;
  }
  &Body {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
