.ourApproach {
  padding-block: 10%;
  padding-inline: 10%;
  display: flex;
  color: #103649;
  background-image: url(../../assets/images/approach_bg.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 100px;
  &:lang(ar) {
    background-position: right center;
  }
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
  }
  &Title {
    width: 35%;
    font-size: 3.25rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Container {
    width: 65%;
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      width: 100%;
      gap: 2rem;
    }
  }
}
