@import "./variables.scss";

:root {
  font-size: 10px;
  --theme-color: rgba(16, 54, 73, 1);
  --montserrat: "Montserrat", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--montserrat);
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

html:lang(ar) {
  direction: rtl;
  & .slick-slide {
    direction: rtl;
  }
  & .slick-dots {
    direction: rtl;
  }
}

.slider {
  .slick-list {
    overflow: visible;
  }

  .slick-dots li {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.slick-active {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      border: 1px solid #4a7fa7;

      span {
        background-color: #103649;
        border-radius: 50%;
      }
    }
  }

  .slick-dots.active-dot-white {
    position: absolute !important;
    width: fit-content;
    inset-inline-end: 0;
    inset-block-end: 10%;
    li span {
      border-radius: 50%;
      background-color: rgba($color: #fff, $alpha: 0.4);
    }

    li.slick-active {
      border-color: #fff;

      span {
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
}

.container {
  padding-inline: 10%;

  // max-width: 1815px;
  // margin: auto;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
}

/* laptops and large screens */
@media screen and (max-width: 1200px) {
  :root {
    font-size: 9px;
  }
}

/* tablets */
@media only screen and (max-width: 992px) {
  :root {
    font-size: 8px;
  }
}

/* mobile phones */
@media only screen and (max-width: 768px) {
  :root {
    font-size: 7px;
  }
}
