.inputsWrapper {
  display: flex;
  gap: 5rem 2.5rem;
  flex-wrap: wrap;

  & > div {
    flex-basis: calc(50% - 1.25rem);

    @media (max-width: 600px) {
      flex-basis: 100%;
    }

    &.fullWidth {
      flex-basis: 100%;
    }
  }
}

.form {
  margin-block-start: 5rem;
}

.formControl {
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.submitBtn {
  all: unset;
  margin-block-start: 5rem;
  padding: 2rem 7rem;
  border-radius: 1.5rem;
  background-color: #497fa6;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background-color: #ccc;
  }
}

.message {
  font-size: 1.5rem;
  margin-top: 2rem;
  text-transform: capitalize;
}

.success {
  color: green;
}

.failure {
  color: red;
}
