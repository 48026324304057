.what {
  padding-inline: 10%;
  padding-block: 10%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background-color: #103649;
  background-image: radial-gradient(
      circle at top right,
      #ffffff10,
      #103649ba 30%
    ),
    radial-gradient(circle at 20% 70%, #ffffff10, #103649ba 30%);
  color: #fff;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Title {
    font-size: 3.5rem;
    font-weight: 700;
  }
  &Card {
    width: 100%;
    border: 1.5px solid rgba(#fff, 0.4);
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    gap: 2rem;
    &Container {
      display: flex;
      gap: 2rem;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    &Title {
      font-size: 1.8rem;
      text-transform: capitalize;
    }
    &Body {
      font-size: 1.5rem;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.7);
    }
    img {
      height: 5rem;
      object-fit: contain;
    }
  }
}
