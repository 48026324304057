.whyUs {
  padding-block-start: 6vw;
  padding-inline: 10%;
  color: #103649;
  display: flex;
  flex-direction: column;
  gap: 7rem;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Title {
    font-size: 3.25rem;
    font-weight: 700;
  }
  &CardContainer {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
}
