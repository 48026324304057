.navbarList {
  flex-grow: 1;
  list-style: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  gap: 3rem;
  height: 100%;
  width: 45%;
  @media screen and (max-width: 1200px) {
    gap: 3rem;
  }
  @media screen and (max-width: 768px) {
    display: none;
    gap: 3rem;
  }
  &.light {
    & .navbarListItem {
      &:hover {
        color: currentColor;
      }
    }
  }
  &Item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    position: relative;
    font-size: 1.6rem;
    font-weight: 500;
    a {
      cursor: pointer;
    }

    &Active {
      color: #4a7fa7;
    }
  }
}

@keyframes open {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
