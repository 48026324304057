.whoWeAre {
  padding-inline: 10%;
  padding-block-start: calc(5% + 10rem);
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-image: linear-gradient(
      to bottom,
      rgba(#00a0de, 0.09),
      rgba(#00a0de, 0.09) 65vw,
      #fff 65vw
    ),
    url(../../assets/images/who_we_are.png);
  background-position: center, right 175px;
  background-repeat: no-repeat;
  background-size: contain, 500px;
  position: relative;
  margin-bottom: 5%;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  @media screen and (max-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      rgba(#00a0de, 0.09),
      rgba(#00a0de, 0.09) 70vw,
      #fff 70vw
    );
  }
  @media screen and (max-width: 668px) {
    background-image: linear-gradient(
      to bottom,
      rgba(#00a0de, 0.09),
      rgba(#00a0de, 0.09) 80vw,
      #fff 80vw
    );
  }
  @media screen and (max-width: 568px) {
    background-image: linear-gradient(
      to bottom,
      rgba(#00a0de, 0.09),
      rgba(#00a0de, 0.09) 90vw,
      #fff 90vw
    );
  }
  &Content {
    display: flex;
    align-items: center;
    color: #103649;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &Title {
    width: 50%;
    font-size: 5rem;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Body {
    width: 50%;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 2rem;
    }
  }
  &Image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 3rem;
    overflow: hidden;
  }
  &Secondary {
    &Content {
      width: 60%;
      color: #103649;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      background-color: #fff;
      padding-block: 3vw;
      padding-inline: 3vw 10vw;
      position: absolute;
      inset-block-start: 85%;
      inset-inline-end: 0;
      border-start-start-radius: 3rem;
      border-end-start-radius: 3rem;
      @media screen and (max-width: 1200px) {
        padding-inline: 2.5vw 5vw;
      }
      @media screen and (max-width: 768px) {
        position: static;
        width: 100%;
        border-radius: 3rem;
        padding-inline: 0;
        padding-block: 0;
      }
    }
    &Title {
      font-size: 2.5rem;
      font-weight: 700;
      @media screen and (max-width: 768px) {
        font-size: 3rem;
      }
    }
    &Body {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.5;
      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
}
